/*!
 * Bootstrap v4.5.3 (https://getbootstrap.com/)
 * Copyright 2011-2020 The Bootstrap Authors
 * Copyright 2011-2020 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@400;700&display=swap');
@import '~antd/dist/reset';
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&family=Rubik:wght@300;400&display=swap');
@import '~bootstrap/scss/bootstrap';
@import '~react-bootstrap-typeahead/css/Typeahead.css';
@import '../../node_modules/@naadi/framework/src/scss/base';
@import '../../node_modules/@naadi/framework/src/scss/common';
$border-radius: 8px;
@import './bootstrap';
@import './app';
@import '_variables', '_theme', '_layout', '_bootstrap-override', '_responsive',
  '_media', '_fonts', 'offcanvasmenu', 'typo', 'select', 'modal', 'itemdetail',
  'accordian', 'mainbox', 'header', 'itemname', 'screenoverlay',
  'box_packed_modal', 'naadi_buttons', 'naadicomon', '_form', '_button',
  '_modal', '_input', '_dropdown';

@import './components/datepicker';
@import './components/table';
@import './components/chips';
@import './components/sidebar';
@import './components/dropdown';
@import './components/select_printer';
@import './components/status';
@import './order/main.scss';
@import './org/main.scss';
@import './report/main.scss';
@import './dashboard/main.scss';
@import './scroll-bar';
@import './tracktrace/main.scss';
@import './group/main.scss';
@import './cutsmart/main.scss';
@import './labeltemplates/main.scss';
@import './shipment/main.scss';
@import './RejectReasons/main.scss';
@import './external-digitalpod/main.scss';

.cursor-pointer {
  cursor: pointer;
}
.error {
  color: $error-color;
}
.warning {
  color: $warning-color;
}
.success {
  color: $success-color;
}
.primary-btn-color {
  color: $primary-button-color;
}
.primary-text-color {
  color: $primary-text-color;
}
.secondary-text-color {
  color: $secondary-text-color;
}
.tertiary-text-color {
  color: $tertiary-text-color;
}
.font-header {
  font-family: 'Rubik Roboto';
}
.body-background {
  background: #ffffff;
  &.bg-offwhite {
    background-color: #f2f2f2;
  }
}
.text-tertiary {
  color: $tertiary-text-color;
}
.Toastify__toast--warning {
  background: #2b3440;
  color: #fff;
}
.Toastify__toast--error {
  background: #2b3440;
  color: #fff;
  z-index: 10000;
}
.Toastify__close-button {
  align-self: center;
}
.Toastify__toast {
  border-radius: 6px;
}
.Toastify__toast-container {
  @include media-breakpoint-up(lg) {
    width: 600px;
  }
}

.column-busy {
  &::after {
    content: ' ';
    @extend .position-absolute;
    top: 0;
    bottom: 0;
    width: 50%;
    height: 2px;
    background-color: $primary;
  }
}

.td-busy {
  height: 1px;
  background-color: #dee2e6;
  @extend .border-0, .p-0;
  &.on {
    animation: TdBusyAnimation 0.6s infinite;
  }
}

@keyframes TdBusyAnimation {
  0% {
    background-color: #dee2e6;
  }
  100% {
    background-color: $primary;
  }
}

.form-control {
  &,
  &:focus {
    background-color: transparent;
  }
}
.grids {
  display: grid;
  grid-template-columns: auto auto auto auto auto auto;
}
.radius40 {
  border: 1px solid #027aff;
  border-radius: 50%;
}
.naadi-modal-default-bound {
  max-width: 500px;
}

.dropdown-toggle::after {
  top: 14px;
  right: 8px;
  @extend .position-absolute;
}

.select-list-group-container {
  z-index: 99;
}
.pr-32 {
  padding-right: 32px;
}
.p-32 {
  padding: 32px;
}
.ps-32 {
  padding-left: 32px;
}
.ps-16 {
  padding-left: 16px;
}

.pe-32 {
  padding-right: 32px;
}
.pe-16 {
  padding-right: 16px;
}

.px-32 {
  padding-right: 32px;
  padding-left: 32px;
}
.px-16 {
  padding-right: 16px;
  padding-left: 16px;
}
.py-32 {
  padding-top: 32px;
  padding-bottom: 32px;
}
.py-16 {
  padding-top: 16px;
  padding-bottom: 16px;
}
.py-8 {
  padding-top: 8px;
  padding-bottom: 8px;
}
.px-8 {
  padding-right: 8px;
  padding-left: 8px;
}
.pb-8 {
  padding-bottom: 8px;
}
.pb-16 {
  padding-bottom: 16px;
}
.pt-16 {
  padding-top: 16px;
}

.pt-8 {
  padding-top: 8px;
}
.ms-16 {
  margin-left: 16px;
}
.mx-32 {
  margin-left: 32px;
  margin-right: 32px;
}
.mx-16 {
  margin-left: 16px;
  margin-right: 16px;
}
.my-8 {
  margin-top: 8px;
  margin-bottom: 8px;
}
.my-16 {
  margin-top: 16px;
  margin-bottom: 16px;
}
.my-64 {
  margin-top: 64px;
  margin-bottom: 64px;
}
iframe#webpack-dev-server-client-overlay {
  display: none;
}
.naadi-text {
  font-family: 'Rubik', sans-serif;
  font-size: 16px;
  font-weight: 400;
}
.h-100 {
  height: 100%;
}
.radius {
  color: #006eed;
  border: 1px solid #006eed;
  border-radius: 8px;
  text-align: center;
}
.py4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.pyy {
  padding-top: 0.3rem;
  padding-bottom: 1.5rem;
}
.rounded-top-20 {
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}
.pxx2 {
  padding-top: 4px;
  padding-right: 8px;
  padding-bottom: 4px;
  padding-left: 16px;
  border-radius: 24px;
  height: 46px;
  width: 92px;
}
.px22 {
  padding-top: 8px;
  padding-right: 16px;
  padding-bottom: 16px;
  padding-left: 16px;
}

@media (max-height: 750px) {
  .custom-gap {
    gap: 0rem;
  }
}
.custom-input:focus {
  background-color: #027aff1a;
  border: none;
  outline: none;
  box-shadow: none;
}
.notes-label {
  font-size: 10px;
  font-weight: 400;
  color: #323232cc;
  padding-left: 10px;
}
.notes-box {
  font-size: 14px;
  color: #32323266;
  padding-left: 10px;
}
.note-container {
  border: 1px solid #dededecc;
  border-radius: 5px;
  height: 80px;
  background-color: #fafafa;
}
.padding-btn {
  padding-top: 10px;
  padding-bottom: 20px;
  gap: 10px;
}

.radius {
  color: #006eed;
  border: 1px solid #006eed;
  border-radius: 8px;
  text-align: center;
}
.py4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.pyy {
  padding-top: 0.3rem;
  padding-bottom: 1.5rem;
}
.rounded-top-20 {
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}
.pxx2 {
  padding-top: 4px;
  padding-right: 8px;
  padding-bottom: 4px;
  padding-left: 16px;
  border-radius: 24px;
  height: 46px;
  width: 92px;
}
.px22 {
  padding-top: 8px;
  padding-right: 16px;
  padding-bottom: 16px;
  padding-left: 16px;
}

@media (max-height: 750px) {
  .custom-gap {
    gap: 0rem;
  }
}
.custom-input:focus {
  background-color: #027aff1a;
  border: none;
  outline: none;
  box-shadow: none;
}

.Track-n-trace-Toast {
  .Toastify {
    z-index: 1000000;
  }
  .Toastify__toast {
    border-radius: 28px !important;
    position: static;
    //left: 20%;
    //right: 20%;
    //bottom: 48px;
    min-height: 32px !important;
    padding: 4px 12px;
    font-size: 14px;
    line-height: 12.59px;
    font-family: 'Rubik';
    font-weight: 400;
    background: #0b1623 !important;
    opacity: 0.85;
    border: 1px solid #0b1623;
    box-sizing: border-box;
    box-shadow: 0px 0px 6px 2px rgba(0, 0, 0, 0.5);
    min-height: 24px;
  }
  .Toastify__toast-container {
    z-index: 1000000;
  }
  .Toastify__toast-body {
    margin-top: 4px;
    margin-bottom: 4px;
    display: flex;
    align-items: center;
    padding: 0 6px;
  }
}

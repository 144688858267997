.delivery-date-col {
  min-width: 78px;
  text-align: right;
}
.order-customer-col {
  min-width: 150px;
  text-align: left;
}
.order-parent-order-col {
  min-width: 150px;
  text-align: left;
}
.order-ref-id-col {
  min-width: 150px;
  text-align: left;
}
.order-order-val-col {
  min-width: 100px;
  text-align: right;
}
.order-main-table {
  max-width: calc(100% - 48px);
  overflow: scroll;
}
.table-responsive {
  thead th {
    position: sticky;
    top: 0;
    background-color: #fff;
    z-index: 1;
  }
}
.order-upload-icon-placement {
  position: relative;
  top: 18px;
  left: 0px;
}
.order-link-order-confirm-typeahead {
  padding: 16px 48px;
}
.order-linked-order-delete {
  position: relative;
  float: right;
  right: 0px;
  top: -18px;
}
.order-show-error-on-top-wrp {
  padding: 12px;
  display: flex;
  font-size: 12px;
  align-items: center;
  .order-show-error-on-top-text {
    padding: 0 16px;
  }
}
.order-label-print-filter-selector {
  .label {
    font-size: 12px;
  }
  .dropdown {
    max-height: 300px;
  }
  .dropdown-printer-name {
    font-size: 16px;
    font-weight: bold;
    line-height: 20px;
  }
  .printer-select-box {
    position: relative;
    &.selected {
      &::before {
        content: '';
        width: 4px;
        height: 100%;
        position: absolute;
        background: #027aff;
        top: 0px;
        left: 0px;
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
      }
    }
  }
  .printer-select-wrp {
    padding-right: 32px;
    position: relative;
  }
  .printer-last-connected {
    color: rgba(50, 50, 50, 0.8);
    font-size: 10px;
    line-height: 12px;
  }
  .printer-status-text {
    font-size: 12px;
    &.success {
      color: $success-color;
    }
    &.failure {
      color: $error-color;
    }
  }
  .printer-status {
    div {
      width: 12px;
      height: 12px;
    }
    position: absolute;
    border-radius: 50%;
    position: absolute;
    float: right;
    background-color: #dedede;
    top: 8px;
    right: 0px;
  }
  .printer-connected {
    background-color: $success-color;
  }
  .print-icon {
    position: absolute;
    top: 4px;
    right: 8px;
  }
  .print-text {
    padding-right: 32px;
  }
  .select-printer-text {
    max-width: 140px !important;
    text-overflow: ellipsis;
    overflow: hidden !important;
  }
}
.label-select-table {
  max-height: 70vh;
  overflow: scroll;
  padding: 0 32px;
}
.order-header-right {
  padding-right: 32px;
}
.order-header-logo-wrp {
}
.order-header-upload-wrp {
}

.p-summary-table-row-border {
  border-bottom: 1px solid #dedede;
}

.p-summary-table-col-r-border {
  border-right: 1px solid #dedede;
}

.p-summary-table-col-l-border {
  border-left: 1px solid #dedede;
}
//
.timeline-container {
  padding: 20px 0px;

  .timeline-icon {
    z-index: 2;
    margin: auto;
    margin-top: 0px;
    img,
    .icon {
      z-index: 2;
      width: 14px;
      height: 14px;
      background-color: #fff;
    }
  }
  .timeline-items {
    height: 500px;
    overflow-x: auto;
  }
  .timeline-item {
    display: flex;
    align-items: flex-start;
    position: relative;
    padding-bottom: 28px;

    &::before {
      content: '';
      position: absolute;
      left: 12px;
      top: 3px;
      bottom: 0;
      width: 1px;
      background: repeating-linear-gradient(
        transparent,
        transparent 5px,
        #ccc 5px,
        #ccc 10px
      );
    }

    &:last-child::before {
      bottom: 100%;
    }

    .status-icon {
      position: relative;
      font-size: 20px;
      margin-right: 10px;
      z-index: 1;

      &.done {
        color: #009e82;
      }

      &.in-progress {
        color: orange;
      }

      &.error {
        color: red;
      }

      &.pending {
        color: grey;
      }
    }

    .timeline-content {
      flex: 1;
      margin-left: 10px;
      .item-title {
        font-size: 16px;
        font-weight: 400;
      }

      .item-subtitle {
        font-size: 14px;
        color: #6c757d;
      }

      .item-date,
      .item-error {
        font-size: 12px;
        color: #6c757d;

        &.error {
          color: red;
        }
      }
    }
  }
}
.icon-container {
  background: #bdfceb;
  border-radius: 100%;
  padding: 2px;
}
.table-column {
  text-overflow: ellipsis;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  padding: 0px 10px;
}

.header-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.text-wrap {
  white-space: normal;
  overflow-wrap: break-word;
}

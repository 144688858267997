.dropdown-table-filter {
  border-bottom: 0px !important;
}

.template-dialog {
  max-width: '748px' !important;
}

.image-width {
  width: '700px';
}

@media (max-width: 300px) {
  .image-width {
    width: '250px';
  }
}
@media (max-width: 490px) {
  .image-width {
    width: '400px';
  }
}
@media (max-width: '796px') {
  .template-dialog {
    .modal-content {
      width: '748px' !important;
    }
  }
}
@include media-breakpoint-down(sm) {
  .image-width {
    width: '300px';
  }
}

@media (max-width: 390px) {
  .body {
    overflow-x: hidden;
  }
  .rectangle-input {
    max-width: 320px;
  }
}

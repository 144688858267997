.cs-body {
  background: white;
}
.cs-box-width {
  width: 320px;
  height: 50px;
  padding: 3px 16px;
}
.cs-disabled-switch {
  pointer-events: none;
  opacity: 0.5;
}
.cs-select-num:disabled {
  background-color: white;
}
.cs-info-icon {
  display: flex;
  align-content: center;
  flex-wrap: wrap;
}
.cs-select-num {
  font-size: 14px;
  line-height: 12px;
  letter-spacing: 0.2px;
  color: #001714;
  border: none;
  border-bottom: 1px solid transparent;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  padding: 0;
  line-height: 15px;
  border: none !important;
}
.cs-select-num:focus {
  border-color: transparent;
  box-shadow: none;
}
.cs-skip-check {
  font-size: 16px;
  letter-spacing: 0.2px;
  font-weight: lighter;
  margin-top: 1px;
  margin-left: 50px;
  line-height: 1.5em;
}
.cs-checkForm {
  height: 30px;
  font-size: 14px;
  padding-right: 5px;
}
.cs-cut-save-button {
  display: flex;
  flex-direction: row-reverse;
  gap: 10px;
  margin-bottom: 20px;
}
.cs-tooltipColor {
  background-color: white;
  background: none;
}
.cs-tooltips {
  font-family: Roboto;
  font-size: 13px;
}
.cs-fieldset1 {
  max-width: auto;
  border: 1px solid #eaeaea;
  border-radius: 5px;
  background-color: #fafafa;
  margin-left: auto;
  margin-right: 25px;
  padding: 20px;
  // height: 250px;
  // width: 650px;
  // border-radius: 8px;
  // background-color:  #fafafa;
  // margin-left: 20px;
}
.cs-box-width1 {
  width: 320px;
  margin-bottom: 10px;
  margin-right: 8px;
  padding: 1px 13px;
  background-color: white;
  height: 50px;
}
.cs-box-width2 {
  margin-bottom: 10px;
  margin-right: 8px;
  padding: 1px 13px;
  background-color: white;
  height: 50px;
}
.cs-fonts {
  font-weight: 300;
}
.cs-form-controls {
  line-height: 18px;
  // padding-left: 15px;
}
.cs-dis-btn {
  border: none;
  background-color: #fafafa;
}
.cs-box {
  margin-left: 35px;
}
.cs-fontstyl {
  line-height: 30px;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  position: relative;
  bottom: 13px;
}
.cs-fontstyl1 {
  line-height: 30px;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
}
.cs-icons {
  display: flex;
  align-items: flex-start;
}
.optimization-heading-text {
  font-size: 14px;
}
.optimization-value {
  font-size: 20px;
  padding: 0 0.5rem;
}
.optimization-cutting-details-parent {
  padding: 1.5rem 0;
  display: flex;
}
.optimization-cutting-details {
  width: 100px;
  padding: 0 1rem;
}
@media screen and (max-width: 567px) and (min-width: 375px) {
  .optimization-heading-text {
    font-size: 8px;
  }
  .optimization-value {
    font-size: 10px;
    padding: 0 0.25rem;
  }
  .optimization-cutting-details {
    width: fit-content;
    padding: 0 0.5rem;
  }
  .cs-border-primary-1 {
    padding: 0px;
  }
}
@media screen and (max-width: 767px) and (min-width: 576px) {
  .cs-box-width {
    width: 300px;
    margin-right: 10px;
  }
}
@media screen and (min-width: 2000px) {
  .cs-box-width {
    width: 420px;
    margin-right: 10px;
  }
}
// @media screen  and (min-width:2500px) {
//   .cs-fieldset1{
//     max-width: 2100px;
//   }
// }
/* Custom CSS class for modal width */
.custom-modal-width {
  max-width: 842px; /* Set your desired width */
}
.custom-modal-widths {
  max-width: 546px; /* Set your desired width */
  max-height: 282px;
}

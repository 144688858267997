$cs-yield-color: #008a50;
$cs-offcut-color: #ffc700;
$cs-waste-color: #f28482;

.cs-yield-bg {
  background-color: $cs-yield-color;
}
.cs-yield-border {
  border-color: $cs-yield-color;
}

.cs-offcut-bg {
  background-color: $cs-offcut-color;
}
.cs-offcut-border {
  border-color: $cs-offcut-color;
}

.cs-waste-bg {
  background-color: $cs-waste-color;
}
.cs-waste-border {
  border-color: $cs-waste-color;
}

.cs-yield-color {
  color: $cs-yield-color;
}

.cs-offcut-color {
  color: $cs-offcut-color;
}

.cs-waste-color {
  color: $cs-waste-color;
}
.cs-border-primary-1 {
  border: 1px solid $primary-color;
  min-height: 200px;
  border-radius: 8px;
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.1);
  padding: 16px;
}
.cs-border-primary-2 {
  border: 2px solid $primary-color;
}
.cs-background-ascent {
  background-color: rgba($primary-color, 0.1);
}

.cs-pattern-actions-label {
  font-family: Roboto;
  font-size: 16px;
}
.scrollable-section {
  padding-top: 0px !important;
  width: 100%;
  position: relative;
  height: calc(100vh - 220px);
  overflow: scroll;
  padding-bottom: 0px;
}

@import 'cutsmart-setting';

.page-size-selector {
  width: fit-content;
  .page-size-display {
    padding: 4px;
    border: 1px solid transparent;
    &.active {
      background: rgba(0, 122, 255, 0.115427);
      border: 1px solid #007bff;
      box-sizing: border-box;
      border-radius: 4px;
    }
  }
  .dropdown-menu {
    max-height: calc(100vh - 200px);
    overflow: auto;
    min-width: 100%;
  }
  &.show {
    &.dropdown {
      .dropdown-content {
        background-color: rgba(#027aff, 0.1);
        border-radius: 4px;
      }
    }
  }
}

.dropdown-table-filter {
  border-bottom: 1px solid #979797;
  padding: 11px 0px;
  width: 100%;
}

.dropdown-item {
  @extend .regular-text !optional;
  @extend .p-3 !optional;

  border-bottom: 1px solid #dedede;

  &:last-child {
    border: none;
  }

  &.active,
  &:active {
    position: relative;
    background: #fff;
    font-weight: 600;
    color: #323232;
    &::before {
      position: absolute;
      content: '';
      background: #007aff;
      width: 4px;
      left: 0;
      top: 0;
      bottom: 0;
    }
  }

  &.disabled,
  &:disabled {
    cursor: not-allowed;
    opacity: 0.6;
  }
}

.dropdown-menu {
  padding: 0;
  background: #ffffff;
  border: 1px solid #c0c0c0;
  box-sizing: border-box;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);
  border-radius: 8px;
}

// __control {

//     naadi-typeahead__single-value
// }

.naadi-typeahead {
  &__control {
    border: none;
  }
  &__value-container {
    &--has-value {
    }
  }
  &__single-value {
  }
}
.naadi-typeahead__control {
  border: none;
}
.no-arrow::after {
  display: none !important;
}

@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@400;700&display=swap');

body {
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.23px;
  background: $bg-grey;
}

.page-title {
  font-family: 'Rubik', sans-serif;
  font-size: 24px;
  letter-spacing: 0.34px;
  line-height: 28px;
}

label,
.label {
  font-weight: bold;
  letter-spacing: 0.2px;
  //text-transform: uppercase;
  font-size: 14px;
  line-height: 16px;
}

.label-s {
  font-size: 11px;
  height: 13px;
  margin-bottom: 4px;
}

button:focus,
.btn:focus,
.btn.focus {
  outline: 0px auto -webkit-focus-ring-color;
  box-shadow: none;
}

.btn-ghost {
  padding: 0px;
  line-height: 1;
  border: 0px;
  font-size: 12px;
  color: $primary-color;
  text-transform: uppercase;
  font-weight: bold;
  font-family: 'Roboto', sans-serif;

  &:hover {
    color: $primary-color;
  }
}

hr {
  border-color: #c0c0c0;
}

b,
.bold {
  font-weight: bold;
}

.ml-48p {
  margin-left: 48px;
}

.pl-4p {
  padding-left: 4px;
}
.fs-10 {
  font-size: 10px;
  line-height: 12px;
}

.fs-12 {
  font-size: 12px;
  line-height: 14px;
}

.fs-14 {
  font-size: 14px;
  line-height: 16px;
}

.fs-24 {
  font-size: 24px;
  line-height: 28px;
}

.fs-36 {
  font-size: 36px;
  line-height: 42px;
}

.cursor-pointer {
  cursor: pointer;
}

// typography

number-highlight {
  font-size: 36px;
  line-height: 42px;
  letter-spacing: 0.514286px;
  color: #323232;
}

.text-label {
  font-size: 10px;
  line-height: 12px;
  letter-spacing: 0.142857px;
  color: #323232;
}

.body-subtext {
  font-size: 12px;
  line-height: 12px;
  letter-spacing: 0.24px;
  color: #a0a0a0;
}
.regular-text-color {
  color: #323232;
}
.regular-text {
  font-size: 14px;
  line-height: 19px;
  letter-spacing: 0.116667px;
  color: #323232;
}

.title-sm {
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.2px;
}

.title-xs {
  font-size: 12px;
  line-height: 14px;
}

.btn-label {
  font-size: 16px;
  line-height: 19px;
  color: #323232;
}

.body-status {
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.228571px;
  color: #323232;
}

.body-highlight {
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.228571px;
  color: #323232;
}

.page-title {
  font-size: 24px;
  line-height: 28px;
  letter-spacing: 0.342857px;
  color: #323232;
}

.title-md {
  font-size: 24px;
  text-transform: uppercase;
  color: #323232;
  font-weight: 500;
}

.text-primary {
  color: #027aff;
}

.text-muted {
  color: #323232;
  opacity: 0.2;
}

.text-gray {
  color: #a0a0a0;
  opacity: 0.3;
}

.create-order-title {
  position: relative;
  margin-bottom: 40px;

  &:before {
    content: '';
    background: #027aff;
    border-radius: 4px;
    width: 4px;
    position: absolute;
    top: 0;
    bottom: 0;
  }
}

.order-detail-title {
  position: relative;
  margin-bottom: 40px;

  &:before {
    content: '';
    background: #17c000;
    border-radius: 4px;
    width: 4px;
    position: absolute;
    top: 0;
    bottom: 0;
  }
}

.rubik-font {
  font-family: 'Rubik';
}

.clickable {
  cursor: pointer;
}

.text-link {
  color: #007bff;
}

.text-error {
  font-size: 10px;
  line-height: 12px;
  letter-spacing: 0.142857px;
  color: #c00000;
}

.form-group {
  position: relative;
  background: #fafafa;
  border: 0.5px solid #eaeaea;
  box-sizing: border-box;
  border-radius: 2px;
  padding: 10px 16px;

  &.has-error {
    background: linear-gradient(
        0deg,
        rgba(227, 69, 82, 0.05),
        rgba(227, 69, 82, 0.05)
      ),
      #ffffff;
    border: 0.5px solid #eaeaea;
    box-sizing: border-box;
    box-shadow: inset 0px -2px 0px #e34552;
    border-radius: 2px;
    margin-bottom: 2rem;
  }
}

.naadi-typeahead__control.naadi-typeahead__control--is-focused {
  border-radius: 0 !important;
  box-shadow: none !important;
  .naadi-typeahead__indicator {
    color: $primary !important;
  }
}

.form-control {
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.2px;
  color: #001714;
  background: transparent;
  border: none;
  border-bottom: 1px solid transparent;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  padding: 0;
  &:focus {
    outline: none;
    box-shadow: none;
    border-bottom: 1px solid $primary;
  }
  &:disabled {
    background: transparent;
    border-bottom: 1px solid transparent;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
}

.footer .form-control {
  @extend .border-0;
}

.form-label {
  font-size: 10px;
  line-height: 14px;
  letter-spacing: 0.1px;
  color: rgba(0, 23, 20, 0.4);
  font-weight: 300;
}

.form-check-label {
  @extend .fs-12;
  color: #323232;
}

.invalid-feedback {
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.2px;
  color: #e34552;
  left: 0px;
}

.custom-file-input::-webkit-file-upload-button {
  visibility: hidden;
}
.custom-file-input::before {
  content: 'Select some files';
  display: inline-block;
  background: linear-gradient(top, #f9f9f9, #e3e3e3);
  border: 1px solid #999;
  border-radius: 3px;
  padding: 5px 8px;
  outline: none;
  white-space: nowrap;
  -webkit-user-select: none;
  cursor: pointer;
  text-shadow: 1px 1px #fff;
  font-weight: 700;
  font-size: 10pt;
}
.custom-file-input:hover::before {
  border-color: black;
}
.custom-file-input:active::before {
  background: -webkit-linear-gradient(top, #e3e3e3, #f9f9f9);
}

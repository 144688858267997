@import url('https://fonts.googleapis.com/css2?family=Rubik&display=swap');

.naadi-text {
  font-family: 'Rubik', sans-serif;
  color: var(--Text-Primary, #323232);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
}
.tableHeader {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  padding-right: 8px;
}

.naadi-text-dropdown {
  word-wrap: break-word;
  .dropdown-toggle {
    &::after {
      display: none;
    }
  }
  width: 70px;
}
.naadi-dropdown-menu {
  width: '280px';
  height: '202px';
  overflow: hidden;
  font-family: 'Rubik', sans-serif;
}
.sub-custom-text-blue {
  color: #006eed;
}

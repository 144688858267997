.list-group {
  .list-group-item {
    &.selected {
      border-left: 3px solid #007aff !important;
      font-weight: bold;
    }
    font-family: 'Rubik', 'Roboto', sans-serif;
    font-size: 14px;
    padding: 6px 12px;
    input {
      padding: 0 16px;
    }
  }
  .list-group-item-input {
    padding-top: 16px;
  }
  max-width: calc(100vw - 300px);
  min-width: 300px;
  line-height: 36px;
  @extend .border;
}
.list-group-group {
  min-width: 0px;
}
.list-group-group-item {
  max-width: calc(100vw - 332px);
  border: 0px;
  position: relative;
  left: -12px;
  border-radius: 0px;
  padding-left: 0px;
  min-width: 0;
  @extend .border-0;
  &:hover {
    background: #ffffff;
  }
  .list-group-item {
    border: 0px;
    padding-left: 28px;
    &:hover {
      background: #ffffff;
    }
  }
}
.list-group-item-input {
  border: 0px;
  margin: 0px;
  min-height: 54px;
  background: #fff;
  padding: 0 12px;
  padding-top: 16px;
  input {
    margin: 0px !important;
    width: 100% !important;
    position: relative;
    border: 2px solid #d8d8d8;
  }
}
.select-toggle {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.list-group-select-option {
  font-family: 'Rubik', 'Roboto', sans-serif;
  font-size: 14px;
  color: #323232;
  background: #ffffff;
  &:hover {
    background: #ffffff;
  }
}
.group-select-dropdown {
  position: absolute;
  top: 4px;
  right: 8px;
}

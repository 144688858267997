@import url('https://fonts.googleapis.com/css2?family=Rubik&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

@mixin reasons-card-style {
  display: flex;
  align-items: center;
  background-color: whitesmoke;
  color: #007bff;
}
.reasons-group-card {
  @include reasons-card-style;
  padding: 0 1rem;
  border-radius: 1rem;
  height: 28px;
  font-family: 'Roboto', sans-serif;
}
.cursor-pointer {
  cursor: pointer;
}
.add-more-btn {
  @include reasons-card-style;
  padding: 1rem;
  border-radius: 0.5rem;
  border: none;
  box-shadow: 'none';
  color: #006eed !important;
  background-color: #027aff1a !important;
  cursor: pointer;
}
.naadi-text-dropdown-reason {
  word-wrap: break-word;
  .dropdown-toggle {
    &::after {
      display: none;
    }
  }
}
.upload-image {
  display: none;
}
.font-weight {
  font-weight: normal;
}
.font-Roboto {
  font-family: 'Roboto', sans-serif;
}
.reject-btn {
  color: #ffffff;
  background: #dedede;
}
.card-selected {
  background-color: #027aff1a;
}
.border-bottom-gray {
  border-bottom: 1px solid gray;
}
.notes {
  border: 1px solid #dededecc;
  border-bottom: 2px solid #006eed;
  border-radius: 4px;
}
.additional-input {
  border: 1px solid #dededecc;
  border-radius: 4px;
}
.input-custom:-moz-placeholder {
  color: rgba(50, 50, 50, 0.4);
}
.addImageModal-title-text {
  font-size: 14px;
  line-height: 16px;
}
.reasons-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #dededecc;
  box-shadow: 0 4px 8px 0px rgba(55, 54, 54, 0.1);
}
.box-response {
  box-sizing: content-box;
}
.img-preview-card {
  width: 56px;
  height: 56px;
}
.remove-icon {
  position: relative;
  top: -39%;
  right: -85%;
  background-color: white;
  border-radius: 70%;
  padding: 3px;
  border: 2px solid #006eed;
  color: #006eed;
}

.item-toggle-appear {
  opacity: 0;
}

.item-toggle-appear.item-toggle-appear-active {
  opacity: 1;
  transition: opacity 300ms linear;
}

.item-toggle-exit {
  opacity: 1;
  transform: scale(1) translateX(0);
}

.item-toggle-exit.item-toggle-exit-active {
  opacity: 0;
  transform: scale(0.97) translateX(5px);
  transition:
    opacity 200ms linear,
    transform 150ms ease-out;
}

.item-toggle-exit-done {
  opacity: 0;
}

.item-toggle-enter {
  opacity: 0;
  transform: scale(0.97) translateX(5px);
  z-index: 1;
}

.item-toggle-enter.item-toggle-enter-active {
  opacity: 1;
  transform: scale(1) translateX(0);
  transition:
    opacity 500ms linear 500ms,
    transform 500ms ease-in-out 200ms;
}

.item-detail-header-row {
  position: sticky;
  top: 60px;
  background: #fff;
  z-index: 100;
}

.notification-menu {
  position: absolute;
  //z-index: 1000;
  background: #fff;
  //border: 1px solid #d8d8d8;
  right: 20px;
  border-radius: 6px;
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }
}

.notificationlink {
  position: relative;
  span {
    bottom: -10px;
    position: absolute;
    right: -12px;
    border: 1px solid #3444;
  }
}
.notification-color-success {
  fill: #ffd200;
}

.notification-color-error {
  fill: red;
}
@media (max-width: 768px) {
  .notification-menu {
    top: 60px;
    left: 0;
    //width: 100%;
    //height: 100vh;
    border-radius: 0;
  }
}

$primary-accent-color: #027aff;
$text-tertiary: rgba(#323232, 0.4);
.track-trace-card-wrp {
  border-radius: 6px;
  background-color: #ffffff;
  border: 1px solid rgba(222, 222, 222, 0.8);
  width: 100%;
  min-height: 80px;
  margin: 8px 0px;
  padding: 12px;
  &.selected {
    border: 2px solid $primary-color;
    border-color: rgba($primary-color, 0.8);
  }
  &.warning-background-color {
    background-color: rgba($warning-color, 0.2);
  }
}
.track-trace-group-card-wrp {
  min-height: 60px;
  border: 1px solid rgba(222, 222, 222, 0.8);
  position: relative;
}

.track-trace-part-name {
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
}
.track-trace-scanned-on {
  font-size: 10px;
  line-height: 12px;
}
.track-trace-scan-status {
  font-size: 10px;
  line-height: 12px;
  color: $primary-button-color;
  &.error {
    color: $error-color;
  }
  .warning {
    color: $warning-color;
  }
  .success {
    color: $primary-button-color;
  }
}
.track-trace-view-mode-text {
  font-family: 'Rubik', sans-serif;
  font-size: 12px;
  line-height: 14px;
}
.track-trace-view-mode-icon-wrp {
  min-width: 32px;
  min-height: 32px;
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: center;
  margin: 0 2px;
  &.selected {
    border: 1px solid $primary-color;
    border-color: rgba(#007bff, 0.5);
    border-radius: 6px;
  }
}

.track-trace-view-image-large-content-top {
  position: absolute;
  top: 16px;
  left: 12px;
  right: 16px;
  padding: 16px;
  height: 120px;
  width: calc(100% - 22px);
  background: transparent;
  .track-trace-part-name {
    color: #ffffff;
  }
  .track-trace-scanned-on {
    color: #ffffff;
  }
}
.track-trace-view-image-large-content-top-bg {
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.61) 67.71%
  );
  transform: matrix(1, 0, 0, -1, 0, 0);
}
.track-trace-item-detail-header {
  color: #0b1623;
  font-size: 24px;
  line-height: 28px;
  padding-left: 48px;
}
.track-trace-item-detail-tab {
  height: 50px;
  min-height: 50px;
  background: rgba(249, 249, 249, 0.8);
  box-shadow: inset 0px -1px 0px #eaeaea;
  align-items: flex-end;
  text-align: center;
  color: rgba(#323232, 0.8);
  .selected {
    color: $primary-color;
    border-bottom: 2px solid $primary-color;
  }
}
.track-trace-item-detail-body {
  padding: 8px 48px;
  font-size: 10px;
  line-height: 12px;
}
.track-trace-item-detail-content-header {
  border-bottom: 2px solid #c0c0c0;
  font-weight: bold;
  padding-bottom: 4px;
}
.track-trace-item-detail-selector {
  width: 32px;
  padding-left: 8px;
  cursor: pointer;
}
.track-trace-item-detail-content-row {
  border-bottom: 1px solid #dedede;
  padding: 8px 0px;
  font-size: 12px;
  &.field-label {
    font-family: 'Rubik', sans-serif;
    font-weight: bold;
  }
}
.track-trace-item-field-detail {
  font-size: 12px;
  line-height: 14px;
  font-family: 'Rubik', sans-serif;
  color: rgba(50, 50, 50, 0.8);
  font-weight: normal;
}

@include media-breakpoint-up(md) {
  .modal-fullscreen {
    &.track-trace-dialog {
      .modal-content {
        border-radius: 16px;
        width: 100%;
      }
      padding: 32px 64px;
    }
  }
}

@include media-breakpoint-down(sm) {
  .track-trace-dialog {
    position: absolute;
    width: 100% !important;
    max-width: 100% !important;
    top: 20%;
    height: 80%;
    .modal-content {
      height: 100%;
    }
  }
}

@media (min-width: 200px) and (max-width: 574px) {
  .track-trace-dialog {
    position: absolute;
    width: 95% !important;
    max-width: 95% !important;
    top: 0px;
    height: auto;
    .modal-content {
      height: 100%;
    }
  }
}

.track-trace-card-status-icons {
  display: flex;
  position: absolute;
  top: 12px;
  right: 12px;
  .status-icon {
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 4px;
    margin-left: 4px;
    &.selected {
      border-radius: 8px;
      &.success {
        background-color: rgba($primary-accent-color, 0.1);
      }
      &.error {
        background-color: rgba($error-color, 0.1);
      }
    }
  }
}
.track-trace-checklist-pending-wrp {
  width: 18px;
  height: 18px;
  margin: 7px;
  text-align: center;
  border-radius: 50%;
  border: 1px solid $success-color;
  color: $success-color;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 10px;
  &.warning {
    border: 1px solid $warning-color;
    color: $warning-color;
  }
  &.error {
    border: 1px solid $error-color;
    color: $error-color;
  }
}
.track-trace-drill-button {
  display: flex;
  width: 100%;
  border-radius: 8px;
  background-color: rgba($primary-accent-color, 0.1);
  height: 32px;
  align-items: center;
  text-align: center;
  justify-content: center;
  font-family: 'Rubik', sans-serif;
  font-size: 12px;
  line-height: 14px;
  &.pending {
    color: $primary-color;
    cursor: pointer;
    .status-icon {
      visibility: hidden;
    }
    &.inverted {
      background-color: rgba($primary-accent-color, 0.2);
      color: #ffffff;
    }
  }
  &.completed {
    background-color: rgba(#f2f2f2, 0.8);
    color: $text-tertiary;
    .status-icon {
      padding-right: 4px;
    }
    &.inverted {
      background-color: rgba(#f2f2f2, 0.2);
    }
  }
}

.track-trace-status-box-wrapper {
  padding-right: 48px;
}

.track-trace-processed-item-wrap {
  border-radius: 8px;
  border: 1px solid #dedede;
}
.track-trace-processed-items {
  padding: 0 32px;
}
.track-trace-group-card-status-count {
  color: $font-color;
  font-size: 12px;
  line-height: 14px;
  position: absolute;
  top: 14px;
  right: 12px;
}
.track-trace-group-card-status-group-count {
  font-size: 12px;
  line-height: 14px;
}
.track-trace-group-card-status-completion {
  width: 100%;
  margin-top: 16px;
  display: flex;
  background: #f2f2f2;
  min-height: 4px;
  width: 100%;
  .percentage {
    border-bottom: 4px solid #027aff;
    width: 100%;
  }
}
.track-trace-group-item-input-wrp {
  padding-top: 16px;
  border-bottom: 2px solid #eaeaea;
  width: 100%;
  min-height: 32px;
  position: relative;
  input {
    color: #000;
    border: 0px;
    &:focus {
      border: 0px;
    }
  }
}
.track-trace-group-item-input-rows {
  padding: 16px 0px;
  border-bottom: 1px solid #eaeaea;
  .checkbox {
    width: 44px;
  }
}
.track-trace-group-item-input-header-checkbox-wrp {
  position: absolute;
  bottom: 6px;
  right: 16px;
}
.track-trace-location-txt {
  color: $primary-color;
  cursor: pointer;
}
.track-trace-location-sel-action-wrp {
  display: initial;
  @include media-breakpoint-down(md) {
    .btn {
      width: 100%;
      margin-top: 16px;
    }
  }
  @include media-breakpoint-up(md) {
    display: flex;
    justify-content: end;
  }
}
.track-trace-highlighted-link {
  background-color: rgba(#027aff, 0.1);
  padding: 4px;
  text-align: center;
  cursor: pointer;
}
.track-trace-reject-camara-link {
  min-width: 32px;
  height: 32px;
  background-color: rgba(#027aff, 0.1);
  border-radius: 2px;
  cursor: pointer;
  padding: 6px 8px;
  display: flex;
  color: $primary;
  font-family: 'Rubik Roboto';
}
.tt-fa-icon-success {
  svg {
    color: $success-color;
  }
}
@media (min-width: 200px) and (max-width: 574px) {
  .track-trace-dialog {
    .modal-header {
      .btn-close {
        position: relative;
        width: 32px;
        padding: 16px 16px;
        margin-top: -16px;
        margin-right: -32px;
        right: 0px;
      }
    }
  }
  .track-trace-camera-image {
    .track-trace-camera-image-action {
      position: absolute;
      bottom: 32px;
    }
  }
  .track-trace-reject-camara-link {
    top: 16px;
    right: 16px;
    position: absolute;
  }
}

@include media-breakpoint-down(sm) {
  .track-trace-header {
    padding-top: 16px !important;
    padding-bottom: 16px !important;
  }

  .tnt-wo-selector-wrp {
    padding-bottom: 16px;
    padding-left: 16px;
  }
}

.track-trace-swipeable-wrp {
  .swipe-action {
    padding: 8px 0;
  }
}

.tt-qr-scanner-line {
  width: 80%;
  height: 2px;
  background-color: red;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  animation: scanline 3s linear infinite;
}

@keyframes scanline {
  0% {
    transform: translate(-50%, -50%) translateY(-100px);
  }
  25% {
    transform: translate(-50%, -50%) translateY(0px);
  }
  50% {
    transform: translate(-50%, -50%) translateY(100px);
  }
  75% {
    transform: translate(-50%, -50%) translateY(0px);
  }
  100% {
    transform: translate(-50%, -50%) translateY(-100px);
  }
}

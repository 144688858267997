.bulk-upload-nav-header {
  font-family: Rubik Roboto;
  font-size: 24px;
  padding-top: 32px;
}
.bulk-upload-status-chips {
  font-size: 12px;
  color: #006eed;
  background: rgba(242, 242, 242, 0.8);
  border-radius: 14px;
  padding: 1px 8px;
  margin: 4px;
  cursor: pointer;
}
.bulk-upload-add-wrp {
  position: absolute;
  bottom: 32px;
  right: 32px;
}
.bulk-upload-import-header {
  font-size: 24px;
  font-weight: normal;
  padding-bottom: 16px;
}
.bulk-upload-import-content {
  min-height: 150px;
  border-radius: 8px;
  border: 1px dashed #027aff;
  width: 100%;
  display: flex;
  align-items: center;
}
.bulk-upload-modal {
  .modal-body {
    padding: 16px;
  }
}
.bulk-upload-upload-separator {
  width: 70%;
  margin: auto;
  padding-top: 16px;
  .separator {
    min-width: 45%;
  }
  .or {
    text-align: center;
    width: 32px;
    font-family: Rubik;
    color: #323232;
  }
  .col {
    div {
      width: 100%;
      height: 1px;
      border: 1px solid rgba(222, 222, 222, 0.8);
    }
  }
}
.bulk-upload-drag-drop-text {
  padding: 16px;
  font-family: Rubik;
  color: rgba(#323232, 0.8);
}
.bulk-upload-browse-file {
  color: #006eed;
  padding: 32px;
}
.bulk-upload-import-footer {
  padding: 16px;
}
.bulk-upload-import-sample {
  color: $primary-color;
  font-family: Rubik;
  font-size: 12px;
  cursor: pointer;
}
.bulk-upload-job-card {
  position: relative;
  height: 52px;
  border: 1px solid rgba(222, 222, 222, 0.8);
  &.selected {
    border: 2px solid #006eed;
  }
  border-radius: 8px;
  cursor: pointer;
  margin-bottom: 8px;
}
.bulk-upload-job-card-status {
  height: 0px;
  position: absolute;
  right: 0px;
  padding: 8px 16px;

  div {
    font-family: Rubik;
    font-size: 10px;
    font-color: #000000;
    padding: 1px 4px;
    background: #ffffff;
    background: rgba(242, 242, 242, 0.8);
    border-radius: 11px;
  }
}
.bulk-upload-job-card-name {
  padding-left: 8px;
  padding-top: 8px;
  width: calc(100% - 102px);
  overflow: hidden;
  text-overflow: ellipsis;
  color: #000;
  font-weight: bold;
  font-size: 16px;
  white-space: nowrap;
}
.bulk-upload-job-card-created {
  padding: 4px 8px;
  color: rgba(50, 50, 50, 0.8);
  font-size: 10px;
  overflow: hidden;
  .created {
    max-width: 50px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
.order-bulk-upload-header {
  padding: 8px 0px;
}

.bulk-upload-job-detail-card {
  border-radius: 8px;
  background-color: #ffffff;
  height: calc(100vh - 80px);
  padding: 16px;
}
.bulk-upload-job-detail-name {
  font-family: Rubik;
  font-size: 24px;
  line-height: 28px;
}
.bulk-upload-job-detail-status {
  display: flex;
  div {
    display: flex;
    font-family: Rubik;
    font-size: 12px;
    font-color: #000000;
    background: rgba(242, 242, 242, 0.8);
    border-radius: 11px;
    padding: 1px 4px;
    .icon {
      padding-top: 6px;
      padding-right: 0px;
    }
  }
}
.bulk-upload-job-detail-status-actions {
  font-family: Rubik;
  font-size: 16px;
  color: $primary-color;
}
.bulk-upload-job-detail-detail {
  padding-top: 16px;
}
.bulk-upload-job-detail-field {
  padding: 4px 0px;
  font-family: Rubik;
  font-size: 12px;
  line-height: 14px;
  color: rgba(50, 50, 50, 0.8);
}
.bulk-upload-job-detail-value {
  font-family: Rubik;
  font-size: 12px;
  line-height: 14px;
  color: #323232;
  padding: 4px 0px;
  padding-left: 8px;
}
.bulk-order-upload-progress-wrp {
  padding-top: 16px;
  .progress-status {
    .progress-type {
      color: $primary-color;
      font-size: 12px;
      line-height: 14px;
      &.failed {
        color: $error-color;
      }
    }
  }
  .task-progress-bar {
    border-radius: 10px;
    height: 4px;
    background: rgba(222, 222, 222, 0.8);
    .completed {
      background: $primary-color;
    }
    .failed {
      background: $error-color;
    }
  }
}
.bulk-order-upload-progress {
  padding: 8px 0px;
}
.bulk-upload-order-detail-card-wrp {
  max-width: 222px;
  height: 140px;
  max-height: 140px;
  position: relative;
}
.bulk-upload-order-detail-card {
  border: 1px solid rgba(222, 222, 222, 0.8);
  border-radius: 10px;
  margin: 4px;
  width: 100%;
  height: 100%;
  padding: 8px;
  .bu-card-header {
    font-weight: bold;
    font-size: 16px;
    max-width: 180px;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  .card-date {
    font-size: 10px;
    color: rgba(50, 50, 50, 0.8);
    .success {
      color: $primary-color;
    }
    .error {
      color: $error-color;
    }
  }
}
.bulk-upload-job-detail-card-scroll {
  overflow-y: scroll;
  overflow-x: hidden;
  max-height: calc(100vh - 300px);
}
.bulk-upload-order-detail-refresh {
  position: absolute;
  top: 12px;
  right: 22px;
  cursor: pointer;
}
.bulk-upload-order-detail-tasks {
  padding: 8px;
  .status {
    line-height: 10px;
    padding: 0px;
  }
  .task-name {
    padding-left: 4px;
    color: #323232;
    font-size: 10px;
    line-height: 14px;
  }
}
.bulk-upload-status-error-symbol {
  display: flex;
  align-items: center;
}
.bulk-upload-status-error-header {
  text-align: center;
  font-weight: 500;
  color: #323232;
  font-family: Rubik;
  font-size: 24px;
  line-height: 28px;
  padding: 16px;
}
.bulk-upload-status-error-message {
  font-size: 12px;
  line-height: 20px;
  color: #323232;
  text-align: center;
  padding-bottom: 16px;
}
.bulk-upload-status-error-action {
  padding: 16px 32px;
}

$bg-grey: #f2f2f2;
$bg-white: #fff;
$drak-blue: #0b1623;
$primary-color: #007bff;
$secondary-color: #2dbecd;
$font-color: #323232;
$label-color: #a0a0a0;
$success-color: #008a50;
$error-color: #c00000;
$warning-color: #ffc107;
$naadi-link-color: #027aff;
$accent-primary: #027aff;
$highlight-warning: #ffc700;
$primary-button-color: #006eed;
$primary-text-color: #323232;
$secondary-text-color: #323232;
$tertiary-text-color: rgba(#323232, 0.4);

$primary-color: #006eed;
$warning-color: #ffd43b;
$text-color: #000;
$border-radius: 8px;

.naadi-button-link {
  font-family: 'Rubik', 'Roboto', sans-serif;
  font-size: 16px;
  color: #007bff;
}

.naadi-add-box {
  float: right;
  right: 16px;
  position: absolute;
  top: -78px;
}

.naadi-sync-offline {
  float: right;
  right: 80px;
  position: absolute;
  top: -78px;
}
.naadi-packing-summary {
  float: right;
  right: 80px;
  position: absolute;
  top: -140px;
}
.naadi-box-print-settings {
  float: right;
  right: 16px;
  position: absolute;
  top: -140px;
}
.naadi-save-btn {
  background-color: $primary-color;
  border: 1px solid $primary-color;
  color: white;
  border-radius: 8px;
  width: 100%;
  padding: '10px';
  cursor: 'pointer';
}
.naadi-back-btn {
  background-color: white;
  border: 1px solid $primary-color;
  color: $primary-color;
  border-radius: 8px;
  width: 100%;
  padding: '10px';
  cursor: 'pointer';
}

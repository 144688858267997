.Home-area1 {
  background: #fff;
  /* height: 100vh; */
}
/* .Home-area1 .list{height: 100vh;} */
.main-home-main-area1 {
  padding-top: 40px;
  padding-bottom: 40px;
  justify-content: center;
  align-items: center;
  height: 100%;
}

/* .appToast > .toastify__toast {
  border-radius: 28px !important;
} */
.Toastify {
  z-index: 1000000;
}
.Toastify__toast {
  border-radius: 28px !important;
  //position: absolute;
  //left: 20%;
  //right: 20%;
  //bottom: 48px;
  min-height: 32px !important;
  padding: 4px 12px;
  font-size: 14px;
  line-height: 12.59px;
  font-family: 'Rubik';
  font-weight: 400;
  background: #0b1623 !important;
  opacity: 0.85;
  border: 1px solid #0b1623;
  box-sizing: border-box;
  box-shadow: 0px 0px 6px 2px rgba(0, 0, 0, 0.5);
  min-height: 24px;
}
.Toastify__toast-container {
  z-index: 1000000;
}
.Toastify__toast-body {
  margin-top: 4px;
  margin-bottom: 4px;
  display: flex;
  align-items: center;
  padding: 0 6px;
}

/* ======== media queries ========= */

@media (min-width: 900px) {
  .mobile {
    display: none;
  }
  .Toastify__toast {
    left: 12.5%;
    right: 12.5%;
    bottom: 87px;
  }
}
.desktop {
  height: 100%;
}

.input-container {
  border: 1px solid #dededecc;
  background: rgba(249, 249, 249, 0.8);
  padding: 8px 16px;
  border-radius: 4px;
  .input-fields {
    background: transparent;
    border: none;
    width: 100%;
    min-height: 28px;
    &:focus-visible {
      border: 0px;
      outline-width: 0px;
    }
  }
}
.status-chip {
  background: rgba(242, 242, 242, 0.8);
  border-radius: 9px;
  padding: 3px 8px;
  margin: 0 4px;
}
.status-text {
  color: rgba(50, 50, 50, 0.4);
  &.active {
    font-size: inherit;
  }
  &.inactive {
    font-size: 11px;
  }
}

.filter-btns {
  margin-right: 10px;
  color: #006eed;
  background: rgba(2, 122, 255, 0.1);
  border-radius: 14px;
  padding: 5px 10px;
  cursor: pointer;
  font-size: 12px;
  line-height: 16px;
  font-weight: 400 !important;
  &.active {
    color: #fff;
    background: #006eed;
  }
}
.standard-icon-wrp {
  width: 32px;
  height: 32px;
  justify-content: center;
  display: flex;
  align-items: center;
}

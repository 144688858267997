.searchBox {
  background: rgba(249, 249, 249, 0.8);
  border: 2px solid rgba(222, 222, 222, 0.8);
  border-radius: 18px;
  padding: 10px 20px;
  padding-left: 26px;
  color: #000;
  font-style: italic;
  width: 394px;
  font-size: 12px;
  line-height: 14.22px;
  font-family: 'Rubik', sans-serif !important;
  font-weight: 400;
}

.no-spin-number {
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type='number'] {
    -moz-appearance: textfield;
  }
}

.select-org-bound {
  padding: 32px;
  width: 100%;
}

.select-org-selection-card {
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  min-width: 256px;
  width: 100%;
  background: #fff;
  padding: 12px;
  padding-top: 32px;
  border-radius: 8px;
  cursor: pointer;
  min-height: 82px;
  margin: 8px;
  &.selected {
    &::before {
      content: '';
      width: 4px;
      height: 100%;
      position: absolute;
      background: #027aff;
      top: 0px;
      left: 0px;
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;
    }
  }
  .org-name {
    font-size: 16px;
    font-weight: bold;
    padding: 8px 0;
  }
}

.txt-input-param {
  width: 50px !important;
  height: 19px !important;
  border-radius: 0px;
  border: none !important;
  font-family: Rubik;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  color: rgba(50, 50, 50, 0.4);
  outline: none;
  padding-bottom: 5px !important;
  .error {
    background-color: red;
  }

  .primary {
    color: #323232;
  }
  .tertiary {
    color: rgba(50, 50, 50, 0.4);
  }
  .link {
    color: #006eed;
  }
}

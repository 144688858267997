.naadi-status {
  display: flex;
  align-items: center;
  background-color: rgba(#f2f2f2, 0.8);
  border-radius: 8px;
  padding: 2px 8px;
  max-height: 16px;
  div {
    font-family: Rubik;
    font-size: 10px;
    font-color: #000000;
    padding: 1px 4px;
    background: transparent;
    border-radius: 11px;
  }
}

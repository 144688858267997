::-webkit-scrollbar {
  width: 6px !important;
  height: 6px !important;
  cursor: default !important;
}

::-webkit-scrollbar-track {
  background: rgb(233, 233, 237) !important;
}

::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.3) !important;
  border-radius: 6px !important;
}

::-webkit-scrollbar-thumb:hover {
  background: rgba(0, 0, 0, 0.4) !important;
}

$sidebar-mini-margin: 56px;
$sidebar-full-margin: 270px;
$sidebar-width: calc(100% - 271px) !default;
$sidebar-mini-width: calc(100% - 56px) !default;
$default-body-bg: #fff;
$white-color: #fff;
$general-transition-time: 300ms !default;
$transition-ease: ease 0s;

@mixin transition($time, $type) {
  -webkit-transition: all $time $type;
  -moz-transition: all $time $type;
  -o-transition: all $time $type;
  -ms-transition: all $time $type;
  transition: all $time $type;
}

.animation-transition-general {
  @include transition($general-transition-time, $transition-ease);
}

@mixin transform-translate-x($value) {
  -webkit-transform: translate3d($value, 0, 0);
  -moz-transform: translate3d($value, 0, 0);
  -o-transform: translate3d($value, 0, 0);
  -ms-transform: translate3d($value, 0, 0);
  transform: translate3d($value, 0, 0);
}

.wrapper {
  position: relative;
  top: 0;
  height: 100vh;

  &.wrapper-full-page {
    min-height: 100vh;
    height: auto;
  }

  .content {
    padding: 2rem;
    height: calc(100vh - 42px);
    overflow: auto;
  }
}
.side-menu-pull {
  float: left;
  overflow: visible;
  position: absolute;
  width: 40px;
  height: 40px;
  padding: 8px;
  right: -40px;
  top: 16px;
  z-index: 100;
  background-color: #0b1623;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  align-items: center;
  display: none;
}
.main-panel {
  height: 100vh;
  width: 100%;
  @include transition(0.4s, cubic-bezier(0.685, 0.0473, 0.346, 1));
}
.side-bar {
  position: fixed;
  top: 0;
  height: 100%;
  bottom: 0;
  width: 260px;
  left: 0;
  z-index: 999;
  transition: 0.5s;
  // @include transition (0.50s, cubic-bezier(0.685, 0.0473, 0.346, 1));
  .sidebar-wrapper {
    position: relative;
    margin-left: 270px;
    width: calc(100vw - 270px);
    height: 100%;
    // @include transition (0.50s, cubic-bezier(0.685, 0.0473, 0.346, 1));
  }
}
.side-menu {
  box-shadow: 0 4px 8px 0;
  .text {
    @include transition(0.4s, cubic-bezier(0.685, 0.0473, 0.346, 1));
  }
}
.side-menu-logo-full {
  position: relative;
  padding: 8px;
  padding-left: 12px;
  overflow: hidden;
}
.is-side-bar-toggled {
  .side-menu {
    width: 270px;
    display: block;
  }
  .side-menu-logo-full {
    //padding-left: 24px;
  }
  .side-menu-pull {
    display: block;
  }
  .main-panel-toggle-overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0px;
    bottom: 0px;
    left: 0px;
    background-color: rgba(0, 0, 0, 0.4);
    z-index: 100;
  }
  .text {
    display: block;
  }
  .nav-item {
    display: flex;
    justify-content: left;
  }
}

@media screen and (max-width: 768px) {
  .main-panel .content {
    padding-left: 15px;
    padding-right: 15px;
  }
}
.nav-item-sidebar {
  &.active {
    .nav-item-sidebar-link {
      color: #17ff00;
      font-weight: bold;
    }
  }
  .text {
    padding-left: 12px;
  }
}
.nav-item-sidebar {
  .nav-link {
    &.nav-item-sidebar-link {
      &:hover {
        color: #ffffff;
      }
      &:focus {
        color: #ffffff;
      }
    }
  }
}
.nav-item-sidebar-link {
  text-align: left;
  display: flex;
  line-height: 20px;
  font-weight: 400;
  cursor: pointer;
  color: white;
  align-items: baseline;
  padding-top: 0px;
  padding-bottom: 0px;
  width: 100%;
  justify-content: space-evenly;
  align-items: baseline;
  .text {
    padding-top: 4px;
  }
}

// Extra small devices (portrait phones, less than 576px)
@include media-breakpoint-up(xs) {
  .is-side-bar-toggled {
    .side-menu {
      width: 270px;
      display: block;
      z-index: 1000;
    }
  }
  .footer {
    padding-left: 0px;
  }
}

// Small devices (landscape phones, less than 768px)
@include media-breakpoint-up(sm) {
  .main-panel {
    //width: $sidebar-mini-width;
    padding-left: $sidebar-mini-margin;
  }
  .is-side-bar-toggled {
    .side-menu {
      width: 270px;
      display: block;
    }
  }
  .footer {
    padding-left: $sidebar-mini-margin;
  }
}

// Medium devices (tablets, less than 992px)
@include media-breakpoint-up(md) {
  .main-panel {
    //width: $sidebar-mini-width;
  }
  .side-menu {
    .text {
      //display: none;
    }
  }
  .naadi-modal-sidebar-padding {
    width: calc(100vw - 10%);
    .modal-content {
      //padding-left: 28px;
    }
  }
  .is-side-bar-toggled {
    .side-menu {
      width: 270px;
      display: block;
      .text {
        display: block;
      }
    }
    &.naadi-modal-sidebar-padding {
      width: calc(100vw - 10%);
      .modal-content {
        //padding-left: 135px;
      }
    }
    .footer {
      padding-left: $sidebar-full-margin;
    }
  }
  .footer {
    padding-left: $sidebar-mini-margin;
  }
}

// Large devices (desktops, less than 1200px)
@include media-breakpoint-up(lg) {
  .main-panel {
    //width: $sidebar-mini-width;
    padding-left: $sidebar-mini-margin;
  }
  .side-menu {
    width: $sidebar-mini-margin;
    .text {
      //display: none;
    }
  }
  .nav-item {
    justify-content: center;
  }
  .nav-item-sidebar {
    max-width: initial;
    .nav-link {
      max-width: initial;
    }
  }
  .footer {
    padding-left: $sidebar-mini-margin;
  }

  .is-side-bar-toggled {
    .side-menu {
      width: $sidebar-full-margin;
      display: block;
      .text {
        display: block;
      }
      z-index: 11;
    }
    .side-menu-pull {
      display: none;
    }
    .main-panel-toggle-overlay {
      display: none;
    }
    .main-panel {
      //width: $sidebar-width;
      padding-left: $sidebar-full-margin;
    }
    .nav-item {
      justify-content: left;
    }
    .nav-item-sidebar {
      max-width: initial;
      .nav-link {
        max-width: initial;
      }
    }
    .footer {
      padding-left: $sidebar-full-margin;
    }
  }
}

.nav-link-submenu-wrp {
  display: none;
  color: #fff;
}
.is-side-bar-toggled {
  .nav-link-submenu-wrp {
    display: initial;
  }
}

// Large devices (desktops, greater than 1200px)
@include media-breakpoint-up(xl) {
  .main-panel {
    //width: $sidebar-width;
    padding-left: $sidebar-full-margin;
  }
  .footer {
    //padding-left: $sidebar-full-margin;
  }

  .side-menu {
    width: $sidebar-full-margin;
    display: block;
    .text {
      display: block;
    }
  }
  .side-menu-logo-full {
    //padding-left: 24px;
  }
  // .naadi-modal-sidebar-padding {
  //   width: calc(100vw - 10%);
  //   .modal-content {
  //     //padding-left: 135px;
  //   }
  // }
  .footer {
    padding-left: $sidebar-full-margin;
  }
  .nav-link-submenu-wrp {
    display: initial;
  }
  .nav-item-sidebar {
    min-width: 100%;
  }
  .nav-item {
    display: flex;
    justify-content: left;
  }
  .nav-item-sidebar-link {
    min-width: initial;
  }
  .is-side-bar-toggled {
    .nav-link-submenu-wrp {
      display: none;
    }
    .side-menu {
      width: $sidebar-mini-margin;
      .text {
        display: none;
      }
    }
    .nav-item-sidebar {
      min-width: initial;
      max-width: $sidebar-mini-margin;
      .nav-link {
        max-width: $sidebar-mini-margin;
      }
    }
    .nav-item-sidebar-link {
      min-width: initial;
    }
    .main-panel {
      //width: $sidebar-mini-width;
      padding-left: $sidebar-mini-margin;
    }
    .side-menu-logo-full {
      padding-left: 12px;
    }
    .text {
      //display: none;
    }
    &.naadi-modal-sidebar-padding {
      width: calc(100vw - 10%);
      .modal-content {
        //padding-left: 28px;
      }
    }
    .footer {
      padding-left: $sidebar-mini-margin;
    }
  }
}

.nav-link-submenu {
  position: absolute;
  top: 0px;
  right: 0px;
}
.side-menu-submenu-item {
  padding-top: 16px;
  &.active {
    color: #17ff00;
    font-weight: bold;
  }
}
.side-bar-profile {
  position: absolute;
  bottom: 0px;
  height: 72px;
  border-top: 2px solid #c0c0c0;
  width: 100%;
  padding: 3px;
  padding-left: 8px;
  color: #ffffff;
  display: flex;
  align-items: center;
  background-color: #0b1623;
  .profile-wrp {
    height: 42px;
    width: 42px;
    padding: 3px;
    border-radius: 50%;
    border: 1px solid #c0c0c0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;
  }
  .profile-user {
    padding-left: 8px;
    align-items: center;
    overflow: hidden;
    display: none;
  }
  .app-install {
    padding-left: 8px;
    align-items: center;
    overflow: hidden;
    display: none;
  }
  @include media-breakpoint-up(xl) {
    .profile-user {
      display: initial;
    }
  }
}
.is-side-bar-toggled {
  .side-bar-profile {
    min-width: 200px;
    .profile-user {
      display: initial;
    }
    .app-install {
      display: initial;
    }
  }
  @include media-breakpoint-up(xl) {
    .side-bar-profile {
      min-width: 56px;
      .profile-user {
        display: none;
      }
    }
  }
}

.side-bar-menu-scroll {
  display: block;
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none;
  overflow-y: scroll;
  padding-bottom: 80px;
  height: calc(100vh - 100px);
  &::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }
}

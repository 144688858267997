@mixin transition($time, $type) {
  -webkit-transition: all $time $type;
  -moz-transition: all $time $type;
  -o-transition: all $time $type;
  -ms-transition: all $time $type;
  transition: all $time $type;
}

.scroll-content {
  height: calc(100vh - 64px);
  overflow: auto;
  padding-bottom: 24px;
}

// Packing
.sub-header {
  box-shadow: 0px 4px 4px -2px rgba(0, 0, 0, 0.3);
  border-bottom-right-radius: 16px;
  border-bottom-left-radius: 16px;
}

.group-select {
  position: relative;
}

.order-select {
  padding-left: 12px;
  position: relative;
  margin-left: 1rem !important;

  &::before {
    position: absolute;
    content: '';
    background: #17c000;
    height: 100%;
    border-radius: 4px;
    width: 4px;
    left: 0;
  }
}

.items-count-wraper {
  position: relative;
  padding-left: 16px;

  &::before {
    position: absolute;
    content: '';
    background: #007bff;
    height: 100%;
    border-radius: 4px;
    width: 4px;
    left: 0;
  }
}

.box-count-wrapper {
  &::before {
    background: $highlight-warning;
  }
}

.box-count-wrapper-green {
  &::before {
    background: #17c000;
  }
}
.info-count-wrapper {
  &::before {
    background: #007bff;
  }
}
.success-count-wrapper {
  &::before {
    background: #008a50;
  }
}
.warn-count-wrapper {
  &::before {
    background: $highlight-warning;
  }
}

.error-count-wrapper {
  &::before {
    background: $error-color;
  }
}

.extra-count-wrapper {
  &::before {
    background: #9d9d9d;
  }
}

.status-progress {
  height: 8px;
  border-radius: 4px;
  background: #d8d8d8;
  margin-top: 8px;

  // &::before
  .status-progress-completed {
    position: absolute;
    content: '';
    background: #17c000;
    height: 8px;
    border-radius: 4px;
    width: 20%;
  }
}

.print-box-wraper {
  border-radius: 16px;

  .print-box-content-box-number {
    padding-right: 64px;
    min-width: 155px;
  }
}

.print-box-content-box-status {
  margin-right: 80px;
}

.table-small {
  font-size: 12px;
  font-weight: normal;

  th {
    font-size: 10px;
    font-weight: bold;
    border-bottom: 1px solid #c0c0c0 !important;
    text-transform: uppercase;
  }

  td,
  th {
    padding: 0.5rem;
    vertical-align: top;
    border-bottom: 1px solid #c0c0c0;
    border-top: 0px;
  }

  .table-select {
    width: 32px;
  }

  .form-check {
    position: relative;
    display: block;
    padding-left: 0px;
    height: 16px;
    width: 16px;
  }

  .form-check-input {
    position: relative;
    margin-top: 0px;
    height: 16px;
    margin-left: 0px;
    width: 16px;
  }

  tr.checked {
    font-weight: bold;
  }

  tr a {
    text-decoration: underline;
  }
}

.custom-control-label::before {
  position: relative;
  margin-top: 0px;
  height: 16px;
  margin-left: 0px;
  width: 16px;
  top: 0rem;
  left: 0rem;
}

.custom-control-label::after {
  position: relative;
  top: -16px;
  left: 0;
  display: block;
  width: 16px;
  height: 16px;
  content: '';
  background: no-repeat 50% / 50% 50%;
}

.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: #17c000;
  background-color: white;
  border-width: 2px;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2317c000' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e");
}

// side menu
.side-menu {
  position: fixed;
  font-size: 16px;
  line-height: 19px;
  background: $drak-blue;
  width: 270px;
  height: 100vh;
  z-index: 11;
  @include transition(0.4s, cubic-bezier(0.685, 0.0473, 0.346, 1));
  .close-container {
    height: 52px;
    width: 76px;
    background: #0b1623;
    position: absolute;
    right: -76px;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
  }

  &.open-nav {
    display: block;
    position: absolute;
    z-index: 333;
    width: 270px;
  }

  .nav-item {
    margin: 8px 0px;
    a {
      color: white;
      display: flex;
      align-items: center;
      padding: 2px 8px;

      img {
        padding-right: 8px;
      }
    }

    &.active {
      &::before {
        position: sticky;
        content: '';
        background: #17c000;
        height: 36px;
        width: 4px;
        left: 0;
      }

      a {
        color: #17ff00;
        font-weight: bold;
      }
    }
  }
}

.footer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100% !important;
  height: 64px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  background: #ffffff;
  //box-shadow: -2px -2px 4px rgba(0, 0, 0, 0.3);
  border-bottom: 4px solid #007bff;
  padding-left: 56px;
  .search {
    height: 100%;
    padding: 0px 24px;
  }
}

.print-box-content-packed-items {
  font-weight: bold;
}

.item-selection-list {
  padding: 16px 0;
}

.item-selection-page {
  height: 100vh;
  background: $bg-white;

  .order-select {
    margin-left: 0px !important;
  }
}

.item-view {
  margin-bottom: 32px;

  .item-view-header {
    font-weight: bold;
    font-size: 14px;
    text-transform: uppercase;
    height: 16px;
    position: relative;
    padding-left: 16px;

    &::before {
      position: absolute;
      content: '';
      background: #007bff;
      height: 100%;
      width: 8px;
      left: 0;
    }
  }

  .item-view-content-items {
    height: 19px;
    width: 19px;
    border: 2px solid #323232;
    border-radius: 50px;
    display: inline-block;

    &.packed {
      border-color: #17c000;
      background: #17c000;
    }
  }

  &.item-view-boxes {
    .item-view-header {
      &::before {
        background: #ffb300;
      }
    }
  }
}
.container-fluid {
  // background-color: #ffffff;
}


$shadow: 0 4px 6px rgba(0, 0, 0, 0.1); 
$warning-color: #FFC700;
.ext-dpod-shadow {
box-shadow: $shadow;
}
.ext-dpod-header {
  background-color: white;
  box-shadow: shadow;

  .title-text {
    font-size: 20px;
  }

  .status-headers {
    display: flex;
    align-items: center;
    width: 100%;
    font-family: 'Roboto';
    font-size: 16px;
    color: #323232CC;
    font-weight: 400;

    .text {
      width: 50%;
      display: flex;
      font-size: 16px;
      font-weight: 400;
      justify-content: center;
      align-items: center;
      cursor: pointer;

      &.active {
        color: #006eed;
        border-bottom: 2px solid #006eed;
      }
    }
  }
}
.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2; 
}

.scan-wrapper {
  position: fixed;
  bottom: 5%;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
}

.scan-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #006eed;
  border-radius: 50%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  width: 60px;
  height: 60px;
  padding: 10px;

  .qr-icon {
    font-size: 24px;
    color: white;
  }
}

.ext-dpod-footer {
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 100%;
  width: 100%;
}
.footer-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 3px;
  color: #666;
  cursor: pointer;

  &:hover {
    color: #0056d3;
  }
}

.d-pod-card-list-container {
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin: 0 20px 20px;
}

.d-pod-card-container {
  width: 100%;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);

  .d-pod-card {
      .d-pod-card-id {
        color: var(--Text-Primary, #323232);
        font-family: Rubik;
        font-size: 24px;
        font-weight: 400;
      }
      .d-pod-card-title{
        color: var(--Text-Primary, #323232);
        font-size: 16px;
        font-weight: 700;
      }

    .d-pod-card-body {
      .location-container {
        font-size: 12px;
        font-weight: 400;

      .location {
        width: 200px;
        color: var(--Text-Secondary, rgba(50, 50, 50, 0.8));
        a {
          color: #0066cc;
          text-decoration: none;
        }
      }

      .directions-button {
        display: flex;
        height: 30px;
        align-items: center;
        justify-content: end;
        background-color: #eaf4ff;
        color: #0066cc;
        border-radius: 5px;
        padding: 8px;
        cursor: pointer;

        svg {
          margin-left: 5px;
        }
      }
       }

      .d-pod-card-info {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .boxes {
          font-size: 1.2rem;

          .box-count {
            font-weight: bold;
            font-size: 1.5rem;
          }
        }

        .vendor-name {
          color: var(--Text-Secondary, rgba(50, 50, 50, 0.8));
          text-align: right;
          font-size: 12px;
        }

        .date-time {
          font-size: 14px;
        }
      }
    }


  }
  .d-pod-pipeline-progress {
    position: relative;
    bottom: 0;
    height: 8px;
    width: 100%;
    background-color: #d9d9d9;
    border-radius: 0 0 10px 10px;
    overflow: hidden;
  }
}
.d-pod-scanner-frame {
  position: absolute;
  top: 20;
  width: 100%;
  height: 100%;

  &::before {
    content: '';
    position: absolute;
    width: 50px; 
    height: 50px; 
    border: 6px solid #17FF00;
    border-right: none;
    border-bottom: none;
    border-top-left-radius: 30px;
    top: 0;
    left: 0;
  }

  &::after {
    content: '';
    position: absolute;
    width: 50px; 
    height: 50px; 
    border: 6px solid #17FF00;
    border-top-right-radius: 30px;
    border-left: none;
    border-bottom: none;
    top: 0;
    right: 0;
  }

  > div::before {
    content: '';
    position: absolute;
    width: 50px; 
    height: 50px; 
    border: 6px solid #17FF00;
    border-bottom-left-radius: 30px;
    border-right: none;
    border-top: none;
    bottom: 0;
    left: 0;
  }

  > div::after {
    content: '';
    position: absolute;
    width: 50px; 
    height: 50px; 
    border: 6px solid #17FF00;
    border-bottom-right-radius: 30px;
    border-left: none;
    border-top: none;
    bottom: 0;
    right: 0;
  }

  &.reject-mode{
  &::before {
    content: '';
    position: absolute;
    width: 50px; 
    height: 50px; 
    border: 6px solid #C00000;
    border-right: none;
    border-bottom: none;
    border-top-left-radius: 30px;
    top: 0;
    left: 0;
  }

  &::after {
    content: '';
    position: absolute;
    width: 50px; 
    height: 50px; 
    border: 6px solid #C00000;
    border-top-right-radius: 30px;
    border-left: none;
    border-bottom: none;
    top: 0;
    right: 0;
  }

  > div::before {
    content: '';
    position: absolute;
    width: 50px; 
    height: 50px; 
    border: 6px solid #C00000;
    border-bottom-left-radius: 30px;
    border-right: none;
    border-top: none;
    bottom: 0;
    left: 0;
  }

  > div::after {
    content: '';
    position: absolute;
    width: 50px; 
    height: 50px; 
    border: 6px solid #C00000;
    border-bottom-right-radius: 30px;
    border-left: none;
    border-top: none;
    bottom: 0;
    right: 0;
  }

}

}

.custom-toast-container-dpod {
  bottom: 50%;
  margin: auto;
}


.custom-toast-body-dpod {
  display: flex;
  justify-content: center;
  align-items: center;
}
.toast-center {
  position: fixed !important;
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
  background-color: #28a745 !important;
  color: white !important;
  border-radius: 8px;
  z-index: 9999;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
  width: fit-content !important;
  padding: 16px;
}

.custom-toast-content {
  display: flex;
  align-items: center;
  gap: 10px; 
  .toast-icon-container {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px; 
  }
  
  .message {
    font-size: 16px;
    line-height: 1.5;
  }
  .message-container {
    display: flex; 
    flex-wrap: wrap;
    font-size: 14px;
    color: white;
    font-weight: 400;
    line-height: 1.5rem;
    word-wrap: break-word; 
    word-break: break-word;
    white-space: normal;
    max-width: 100%;
    overflow-wrap: break-word;
  }
  

  .undo-button {
    background: transparent;
    border: none;
    color: white;
    font-size: 14px;
    font-weight: bold;
    cursor: pointer;
  }
  
  .undo-button:hover {
    opacity: 0.8;
  }
}


.swipe-container {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0px 20px;
}

.swipe-track {
  position: relative;
  width: 100%;
  height: 52px;
  border: 2px solid #006EED;
  border-radius: 8px;
  overflow: hidden;
  cursor: pointer;

}
.completed {
  border-color: #008A50;
  background: #008A50;
  cursor: default; 
}
.swipe-progress {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
}

.swipe-handle {
  position: absolute;
  top: 50%;
  left: 2px;
  transform: translateY(-50%);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 76px;
  height: 44px;
  background-color: #006EED;
  border-radius: 8px;
  border-top-right-radius: 24px;
  border-bottom-right-radius: 24px;
  color: #fff;
  z-index: 2;
  transition: width 0.3s ease;
}

.check-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  font-size: 24px;
  z-index: 3;
}

.swipe-text {
  position: relative;
  top: 50%;
  left: 80%;
  transform: translate(-50%, -50%);
  font-size: 16px;
  color: #006EED;
  z-index: 1;
  pointer-events: none;
}

.swipe-track.completed .swipe-text {
  color: #fff;
  border-color: #008A50;

}
.detail-container{
  background: #fafafa;
  border: 1px solid #027AFF;
  border-radius: 16px;
  .status {
    display: flex;
    align-items: end;
    font-family: Arial, sans-serif;
    gap: 4px;
    height: 19px;
    margin-bottom: 15px;
  }
  .bottom-dashed-line {
    border-bottom: 1px dashed #000;
  }
  
}
.bottom-modal-container{
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background: white;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  padding: 16px;
}
.background-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(50, 50, 50, 0.8);
  z-index: 999;
}
.dpod-warning-btn {
  border: 1px solid $warning-color;
  border-radius: 8px;
  width: 100%;
  padding: '10px';
  cursor: 'pointer';
  color: black;
  background-color: $warning-color;
}

.swiper-body-container {
  display: flex;
  justify-content: center; 
  align-items: center;
  height: 100%; 
}

.swiper-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: auto;
}

.swiper-slide img {
  max-width: 100%;
  max-height: 400px;
  object-fit: contain;
}

.swiper-pagination {
  position: relative;
  bottom: -20px;
  margin-top: 20px; 
  text-align: center;
}

.swiper-pagination-bullet {
  width: 10px;
  height: 10px;
  background-color: #000;
  opacity: 0.6;
}

.swiper-pagination-bullet-active {
  background-color: #006EED;
  opacity: 1;
}


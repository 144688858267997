.table {
  border-spacing: 0;

  thead {
    th {
      @extend .text-uppercase;
      text-transform: uppercase;
      font-style: normal;
      font-weight: bold;
      font-size: 10px;
      line-height: 12px;
      color: #323232;
      // border-bottom: 2px solid #C0C0C0;
      vertical-align: top;
      padding: 8px 4px 8px 4px;
      .table-header {
        padding: 8px 4px 8px 4px;
      }
      border-bottom: 1px solid $primary-color;
    }
  }

  tbody {
    tr {
      td {
        font-size: 12px;
        color: #323232;
        vertical-align: middle;
        border-bottom: 1px solid #dedede;
        border-top: none;
        min-width: 120px;
        padding: 8px 4px 8px 4px;
      }
    }
  }

  .filter-row {
    td {
      padding: 7px 0.25rem 0px 0.25rem;
      border: none;
    }
  }
}

.table-responsive {
  overflow-x: inherit;
}

.bold-link {
  font-weight: bold;
  font-size: 12px;
  color: #027aff;
}

.table-search-input {
  border: none;
  border-bottom: 1px solid #979797;
  padding: 7px 0px;
  width: 100%;

  &::placeholder {
    font-style: italic;
    font-size: 12px;
    color: rgba(179, 179, 179, 0.8);
  }

  &:focus {
    outline: none;
  }
}

.table-responsive {
  overflow-x: auto;
}

.table-editable-input {
  font-size: 12px;
  line-height: 14px;
  color: #323232;
  width: 100%;
  border: none;
  padding: 10px 0px;
  border-bottom: 1px solid #afd6ff;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  &:focus {
    outline-color: #afd6ff;
  }
  &::disabled {
    border: none;
    cursor: no-drop;
    background: none;
  }
}
input:disabled {
  border: none;
  cursor: no-drop;
  background: none;
}

.table-input-display {
  padding: 10px 0px;
}

.my-10 {
  margin: 10px 0px;
}

.tableFixHead {
  .table-responsive {
    overflow-y: auto;
    height: 75vh;
    background: #fff;

    thead {
      th {
        position: sticky;
        top: 0;
        background: #fff;
        padding: 0px;
        border: none;
      }
    }
  }
}

.table-header {
  border-bottom: 1px solid #027aff;
  text-transform: uppercase;
  min-height: 42px;
  &.active {
    border-bottom: 2px solid #027aff;
  }
}

.bom-item-error {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  background: #c00000;
  margin-right: 4px;
}

.bom-item-pending {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  background: #ffd200;
}
.bom-item-success {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  background: $success-color;
}
.bom-item-ready-to-release {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  border: 1px solid $success-color;
  &.release-in-progress {
    border: 1px solid #000;
  }
}
.sticky-table {
  .table {
    border: none;
    .tr {
      :last-child {
        .td {
          border-bottom: 0;
        }
      }
    }

    .th {
      border-bottom: 2px solid #c0c0c0;
      background: #fff;
      font-weight: bold;
      font-size: 10px;
      line-height: 12px;

      color: #323232;
      text-transform: uppercase;
      @extend .py-2;
      @extend .px-3;
    }

    .td {
      border-bottom: 1px solid #d8d8d8;
      background-color: #fff;
      overflow: hidden;
      @extend .px-3;
      padding-top: 12px;
      padding-bottom: 12px;
      .resizer {
        display: inline-block;
        width: 5px;
        height: 100%;
        position: absolute;
        right: 0;
        top: 0;
        transform: translateX(50%);
        z-index: 1;

        &.isResizing {
          background: red;
        }
      }
    }

    &.sticky {
      overflow: scroll;

      .header,
      .footer {
        position: sticky;
        z-index: 1;
        width: fit-content;
      }

      .header {
        top: 0;
        @extend .text-uppercase;
        font-style: normal;
        font-weight: bold;
        font-size: 10px;
        line-height: 12px;
        color: #323232;
        vertical-align: top;
        width: 100%;
      }

      .footer {
        bottom: 0;
        box-shadow: 0px -3px 3px #ccc;
      }

      .body {
        position: relative;
        z-index: 0;
      }

      [data-sticky-td] {
        position: sticky;
      }

      [data-sticky-last-left-td] {
        // box-shadow: 2px 0px 3px #ccc;
      }

      [data-sticky-first-right-td] {
        // box-shadow: -2px 0px 3px #ccc;
      }
    }
  }
}

.tableWrap {
  display: block;
  max-width: 100%;
  // overflow-x: scroll;
  overflow-y: hidden;
}
.bom-more-action-selector {
  transform: scale(0.7);
  margin-left: 8px;
}
.report-table-row {
  font-size: 12px;
}

.table-row-hover {
  &:hover {
    background-color: rgba(#027aff, 0.1);
  }
}

.print-box-container,
.print-box-content-packed-list {
  .col-sm-12 {
    padding-bottom: 16px !important;
  }
}
.print-box-container {
  padding-left: 24px;
  padding-right: 24px;
}

.close-container {
  display: none !important;
}
@media (min-width: 1025px) {
  .content-wraper,
  .navbar-toggler {
    display: none !important;
  }

  .print-box-container {
    padding-left: 24px;
    padding-right: 24px;
  }
  .side-menu {
    .logo-icon {
      display: none;
    }
    .logo,
    .text {
      display: block;
    }
  }
}
@media (max-width: 1024px) {
  .navbar-toggler {
    display: block;
  }
  .content-wraper,
  .footer {
    width: calc(100% - 48px);
  }
  .side-menu {
    .logo-icon {
      display: block;
    }
    .logo,
    .text {
      display: none;
    }
    &.open-nav {
      .logo-icon {
        display: none;
      }
      .logo,
      .text {
        display: block;
      }

      .close-container {
        display: flex !important;
      }
    }
  }
  .side-menu {
    width: 56px;
  }
  .print-box-container {
    padding-left: 24px;
    padding-right: 24px;
  }
}
@media (max-width: 576px) {
  .print-box-container {
    padding-left: 16px;
    padding-right: 16px;
  }
  .content-wraper.ml-48p {
    margin-left: 0px !important;
  }
  .side-menu {
    display: none;
    &.open-nav {
      display: block;
      position: absolute;
      z-index: 333;
      width: 240px;
    }
  }
  .content-wraper,
  .footer {
    width: 100%;
  }
  .order-select {
    margin-left: 0px !important;
  }
  .col-sm-12 {
    padding: 0px;
    padding-bottom: 16px !important;
  }
  .print-box-content-box-status {
    margin-right: 20px;
  }
}
.item-selection-list {
  width: 100%;
}

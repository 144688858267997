.naadi-report-customize-header {
  font-family: 'Rubik', 'Roboto', sans-serif;
  font-size: 12px;
}
.naadi-report-customize-header-main {
  font-family: 'Rubik', 'Roboto', sans-serif;
  font-size: 16px;
  padding: 16px 0px;
  margin-right: 16px;
  border-bottom: 1px solid #979797;
}
.naadi-report-customize-input-wrp {
  padding: 8px 40px 8px 8px;
  input {
    width: 100% !important;
    padding: 0 16px;
    border: 1px solid #bcbcbc;
    height: 32px;
  }
}
.naadi-report-customize-selected {
  display: flex;
  align-items: center;
  height: 43px;
  border-bottom: 1px solid #dedede;
  margin-right: 16px;
  position: relative;
}
.naadi-report-customize-delete {
  position: absolute;
  right: 16px;
  cursor: pointer;
}
.naadi-report-customize-add {
  position: absolute;
  right: 16px;
  cursor: pointer;
}

.naadi-report-customize-freeze-wrp {
  border: 1px solid #e0e0e0;
  padding: 16px;
  border-radius: 8px;
  margin: 16px 0;
  margin-right: 16px;
  display: flex;
  position: relative;
  .freeze-step-wrp {
    position: absolute;
    right: 48px;
    display: flex;
  }
}

.main-container {
  padding-left: 15px;
  padding-top: 28px;
  max-width: 850px;
  width: fit-content;
  display: flex;
  width: 100%;
}
.group-row-divider {
  padding: 16px;
  border-bottom: 1px solid rgba(#dedede, 0.8);
}

.rectangle-input {
  background: rgba(249, 249, 249, 0.8);
  border: 1px solid rgba(222, 222, 222, 0.8);
  box-sizing: border-box;
  border-radius: 4px;
  width: 320px;
  height: 52px;
  padding-bottom: 7px;
  padding-right: 6px;
  &.highlight {
    box-shadow: inset 0px -2px 0px #006eed;
  }
  &.error {
    box-shadow: inset 0px -2px 0px red;
    background: rgba(215, 0, 0, 0.04);
  }
}

.txt-input {
  width: 262px !important;
  height: 19px !important;
  border-radius: 0px;
  border: none !important;
  font-family: Rubik;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  color: rgba(50, 50, 50, 0.4);
  outline: none;
  padding-bottom: 5px !important;
  .error {
    background-color: red;
  }

  .primary {
    color: #323232;
  }
  .tertiary {
    color: rgba(50, 50, 50, 0.4);
  }
  .link {
    color: #006eed;
  }
}

.lbl-input {
  border-radius: 0px;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 12px;
  display: flex;
  color: rgba(50, 50, 50, 0.8);
}

.lbl-padding {
  padding-top: 8px;
  padding-bottom: 8px;
}

.searchUser {
  background: rgba(249, 249, 249, 0.8);
  border: 0px;
  border-bottom: 2px solid rgba(222, 222, 222, 0.8);
  padding: 10px 20px;
  padding-left: 26px;
  color: #000;
  font-style: italic;
  font-size: 12px;
  line-height: 14.22px;
  font-family: 'Rubik', sans-serif !important;
  font-weight: 400;
}
.searchModal {
  border: 0px !important;
  color: #000;
  font-style: italic;
  font-size: 12px;
  line-height: 12px;
  font-family: 'Rubik', sans-serif !important;
  font-weight: 400;
}

.bullet0 {
  background: #c3b8f1;
  border-radius: 50%;
  height: 10px;
  width: 10px;
  margin-right: 8px;
  margin-top: 4px;
}
.bullet1 {
  background: #95c4fa;
  border-radius: 50%;
  height: 10px;
  width: 10px;
  margin-right: 8px;
  margin-top: 4px;
}
.bullet2 {
  background: #e9b5b5;
  border-radius: 50%;
  height: 10px;
  width: 10px;
  margin-right: 8px;
  margin-top: 4px;
}
.bullet3 {
  background: #c4c4c4;
  border-radius: 50%;
  height: 10px;
  width: 10px;
  margin-right: 8px;
  margin-top: 4px;
}

.regular-size {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 400;
  font-size: 14px !important;
  line-height: 14px;
}
.small-size {
  font-size: 12px !important;
}

.border-bottom {
  background: rgba(249, 249, 249, 0.8);
  border-bottom: 2px solid rgba(222, 222, 222, 0.8);
  padding: 10px 20px;
}

.tap-container {
  width: 417px;
  height: 116px;
  background: #f9f9f9;
  text-align: center;
  padding-top: 50px;
  color: #006eed;
}

.popup-container {
  align-items: flex-start;
  text-align: left;
  padding-left: 30px;
  padding-top: 24px;
}

.heading {
  font-size: 24px;
  line-height: 28px;
}

.search-line {
  background: rgba(222, 222, 222, 0.8);
  border: 1px solid #eaeaea;
  box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.middle-line {
  background: rgba(249, 249, 249, 0.8);
  border: 1px solid #eaeaea;
}

.rectBranch {
  height: 64px;
  border: 4px solid #17c000;
  border-bottom: 0px;
  border-right: 0px;
  border-top: 0px;
  padding-left: 12px;
}
.selectBranch {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 28px;
  color: #006eed;
}

.branchName {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.2px;
  color: #a0a0a0;
}

.btn-save-changes {
  min-width: 137px !important;
  min-height: 48px;
  word-wrap: break-word;
}
.floating-div {
  position: fixed;
  bottom: 40px;
  right: 150px;
  height: 47px;
  width: 47px;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 47px;
}
@media (max-width: 300px) {
  .body {
    overflow-x: hidden;
  }
  .align-left {
    text-align: left !important;
  }
  .Toastify__toast-container {
    width: 200px;
  }
  .btn-close {
    width: 240px;
  }
  .box {
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
  }
  .popup-box {
    height: 100%;
    margin-bottom: 20px;
  }
  .box {
    max-height: 100%;
  }
  .modal-content {
    margin-left: 0px;
    margin-top: 300px;
  }
}

@media (max-width: 320px) {
  .body {
    overflow-x: hidden;
  }
  .rectangle-input {
    width: 220px;
  }
  .align-left {
    text-align: left !important;
  }
  .Toastify__toast-container {
    width: 200px;
  }
  .btn-close {
    width: 220px !important;
  }
}
@media (max-width: 490px) {
  .box {
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    max-height: 100%;
    padding-left: 20px;
    padding-right: 20px;
  }
  .Toastify__toast-container {
    width: 300px;
  }
  .popup-box {
    height: 100vh;
    margin-bottom: 20px;
  }

  .modal-content {
    margin-top: 190px;
    margin-left: 0px;
  }

  .btn-close {
    width: 240px;
  }
}
.Toastify__toast {
  border-radius: 28px !important;
  position: absolute;
  left: 20%;
  right: 20%;
  bottom: 48px;
  padding: 4px 12px;
  font-size: 10px;
  line-height: 12.59px;
  font-family: 'Rubik';
  font-weight: 400;
  background: #0b1623 !important;
  opacity: 0.85;
  border: 1px solid #0b1623;
  box-sizing: border-box;
  box-shadow: 0px 0px 6px 2px rgba(0, 0, 0, 0.5);
  min-height: 32px;
}
.Toastify__toast-container {
  width: 600px;
}
.Toastify__toast-body {
  margin-top: 4px;
  margin-bottom: 4px;
  display: flex;
  align-items: center;
  padding: 0 6px;
}
.Toastify__toast--warning {
  background: #2b3440;
  color: #fff;
}
.Toastify__toast--error {
  background: #2b3440;
  color: #fff;
  z-index: 10000;
}
.Toastify__close-button {
  align-self: center;
}
.Toastify__toast {
  border-radius: 6px;
}
.uploadImageBtn {
  padding: 30px;
  border: none;
  background: #f2f2f2cc;
  border-radius: 50%;
  margin-bottom: 10px;
}
.uploadImageBtn1 {
  border: none;
  border-radius: 50%;
}
@include media-breakpoint-down(sm) {
  .modal-content-text-align {
    text-align: left !important;
  }
  .Toastify__toast-container {
    width: 100%;
  }
}
@media screen and (max-width: 768px) {
  .terms-modal-dialog-wrp {
    margin: 0px !important;

    min-width: 100% !important;
    bottom: 0px;
    max-height: 756px;
    .modal-content {
      border-bottom-left-radius: 0px;
      border-bottom-right-radius: 0px;
      position: fixed !important;
      bottom: 0px;
      margin-left: 0px;
    }
  }
}
.modal-content-text-align {
  text-align: center;
}

.ant-dropdown-placement-bottomLeft > .ant-dropdown-arrow,
.ant-dropdown-placement-bottomRight > .ant-dropdown-arrow {
  border-bottom: 1px solid #c0c0c0 !important;
  border-right: 1px solid #c0c0c0 !important;
  background: #fff;
  box-shadow: rgba(0, 0, 0, 0.5) 0px 2px 4px;
  z-index: -1;
}

.txt-input-param {
  width: 60px !important;
  height: 19px !important;
  border-radius: 0px;
  border: none !important;
  font-family: Rubik;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  color: rgba(50, 50, 50, 0.4);
  outline: none;
  padding-bottom: 5px !important;
  .error {
    background-color: red;
  }

  .primary {
    color: #323232;
  }
  .tertiary {
    color: rgba(50, 50, 50, 0.4);
  }
  .link {
    color: #006eed;
  }
}

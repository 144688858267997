.position-relative {
  position: relative;
}
height-0 {
  height: 0px;
}
height-0-important {
  height: 0px !important;
}
.height-100-percent {
  height: 100%;
}
.width-100-percent {
  width: 100%;
}
width-20px {
  width: 20px;
}
width-30px {
  width: 30px;
}

.naadi-error {
  color: $error-color;
}
.naadi-success {
  color: $success-color;
}
.max-width-90 {
  max-width: 90% !important;
}
.max-width-80 {
  max-width: 80% !important;
}
.max-height-80 {
  max-height: 80% !important;
}
.padding-0 {
  padding: 0px;
}
.width-auto {
  width: auto;
}
.display-initial {
  display: initial;
}
.display-block {
  display: block;
}
.z-index-10 {
  z-index: 10;
}
.naadi-link {
  color: $naadi-link-color;
  cursor: pointer;
}
.br-0 {
  border-right: 0px !important;
}
.btn {
  padding: 12px 16px !important;
}
.secondary-text {
  color: $font-color;
  color: rgba($font-color, 0.8);
}
@keyframes errorBackgroundToWhite {
  0% {
    background-color: #ffd0cc;
  }
  70% {
    background-color: #ffd0cc;
  }
  100% {
    background-color: #ffffff;
  }
}
@keyframes successBackgroundToWhite {
  0% {
    background-color: #a6ffda;
  }
  70% {
    background-color: #a6ffda;
  }
  100% {
    background-color: #ffffff;
  }
}
@keyframes warningBackgroundToWhite {
  0% {
    background-color: #ffedab;
  }
  70% {
    background-color: #ffedab;
  }
  100% {
    background-color: #ffffff;
  }
}
.error-background-color {
  background-color: $error-color;
}
.success-background-color {
  background-color: $success-color;
}

.warning-background-color {
  background-color: $warning-color;
}

.progress-background-color {
  background-color: $accent-primary;
}

.pending-background-color {
  background-color: #dedede;
}

.error-scan-background {
  background-color: #ffd0cc;
  animation-duration: 10s; /* same as transition duration */
  animation-name: errorBackgroundToWhite;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}
.success-scan-background {
  background-color: #a6ffda;
  animation-duration: 10s; /* same as transition duration */
  animation-name: successBackgroundToWhite;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}
.warning-scan-background {
  background-color: #ffedab;
  animation-duration: 10s; /* same as transition duration */
  animation-name: warningBackgroundToWhite;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}
.large-click-area {
  position: relative;
  width: 48px;
  height: 48px;
  left: -16px;
  top: -16px;
  padding: 16px;
  cursor: pointer;
}

.naadi-mobile-only {
  display: none !important;
}
@include media-breakpoint-down(sm) {
  .naadi-mobile-hide {
    display: none !important;
  }
  .naadi-mobile-only {
    display: initial !important;
  }
}

.table {
  > :not(:first-child) {
    border-top: 0px;
  }
}
a {
  &.bold-link {
    text-decoration: none;
  }
  &.link {
    text-decoration: none;
  }
}
.pl-2 {
  padding-left: 0.5rem !important;
}
.pr-2 {
  padding-right: 0.5rem !important;
}

.details-view {
  th {
    border-top: none;
  }
}
footer.footer {
  input {
    border: none;
  }
  input:focus {
    border: none;
    box-shadow: none;
  }
  .btn-ghost {
    display: flex;
    align-self: center;
  }
}

.editable-text {
  cursor: pointer;
  text-decoration: underline;
}

.qty-input {
  width: 60px;
  margin-right: 10px;
}

div[disabled] {
  pointer-events: none;
  opacity: 0.3;
}
.list-view-icon {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}
.detail-view-packed-qty {
  min-width: 132px;
}
.packing-list-filter {
  input {
    border: none;
    border-bottom: 1px solid $primary;
    padding: 7px 0px;
    width: 100%;
    &:focus {
      outline: 0px !important;
    }
  }
  .input-group-append {
    position: absolute;
    right: 0px;
    padding: 0px;
    width: 72px;
    overflow: hidden;
    button {
      width: 20px;
    }
  }
}
.naadi-packing-print-label {
  max-width: 80vw;
  max-height: 62vw;
}
.checklist-pending {
  border: 2px solid $highlight-warning;
  border-radius: 50%;
  width: 12px;
  height: 12px;
  text-align: center;
  margin-left: 8px;
  font-size: 8px;
  line-height: 10px;
  cursor: pointer;
  &.no-pending {
    border: 2px solid $success-color;
  }
}
.checklist-modal-separator {
  border: 1px solid #eaeaea;
  height: 1px;
}
.operation-checklist-line {
  padding: 12px;
  border-bottom: 1px solid rgba(234, 234, 234, 0.8);
}
.operation-checklist-modal {
  @include media-breakpoint-up(md) {
    width: 80vw !important;
  }
  @include media-breakpoint-up(lg) {
    width: 50vw !important;
  }
}
.print-box-content {
  padding-right: 16px;
}
.main-box-detail-list {
  padding: 8px;
  border-bottom: 1px solid #dedede;
}

.naadi-report-nav-icon-wrp {
  transform: scale(0.8);
  @extend .pt-2, .pb-2, .pl-2 !optional;
}
.naadi-report-add-note-wrp {
  min-height: 200px;
  padding: 16px 32px;
}
.naadi-report-add-note-header {
  font-size: 20px;
  font-weight: bold;
  text-align: center;
}

.naadi-report-download-card {
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  min-width: 256px;
  width: calc(100% - 24px);
  background: #fff;
  padding: 12px;
  border-radius: 8px;
  cursor: pointer;
  min-height: 82px;
  margin: 8px;
  .report-name {
    font-size: 16px;
    font-weight: bold;
    padding: 8px 0;
  }
}

.chip {
  display: inline-block;
  margin-left: 0.5rem;
  margin-top: 0.5rem;
  cursor: pointer;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;

  background: #afd6ff;
  border: 1px solid #027aff;
  box-sizing: border-box;
  border-radius: 10px;
  padding: 3px 8px;

  &:hover {
    opacity: 0.6;
  }
}

.chip-cross-btn {
  background: #ffffff;
  margin-left: 8px;
  border-radius: 50%;
  height: 11px;
  width: 11px;
}

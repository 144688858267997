.naadi-dashboard-card {
  background: #ffffff;
  width: 100%;
  height: 100%;
  border-radius: 8px;
}
.widget-action-wrap {
  position: absolute;
  right: 8px;
  top: 8px;
  width: 64px;
}
.widget-action-options {
  position: absolute;
  top: -2px;
}
.widget-packing-box-number {
  font-size: 32px;
  font-family: 'Rubik', 'Roboto', sans-serif;
  text-align: center;
  padding: 8px 0;
}
.widget-packing-box-label {
  font-size: 12px;
  font-family: 'Rubik', 'Roboto', sans-serif;
  text-align: center;
}
.widget-header-text {
  padding-left: 32px;
  padding-top: 16px;
  font-family: 'Rubik';
  color: #323232;
  text-transform: uppercase;
}
